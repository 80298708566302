<template>
  <div class="switching">
    <Header />
    <HeaderBottom />
    <div class="switching-order">
      <b-container class="switching-container">
        <div class="switching-title mb-4">
          <b-row>
            <div class="col-lg-4 col-md-6 mb-4">
              <div class="back-nav">
                <b-link to="/portfolio"
                  ><b-icon class="mr-2" icon="chevron-left"></b-icon
                  ><span>Kembali</span></b-link
                >
              </div>
            </div>
            <div class="col-lg-8 col-md-6">
              <h1 class="ml-5">Alihkan Produk</h1>
            </div>
          </b-row>
        </div>
        <div class="switching-from">
          <b-row class="mt-5 mb-2">
            <b-col class="my-auto">
              <p class="title">Dari</p>
              <p class="subtitle">Investasi Anda sekarang</p>
            </b-col>
          </b-row>
        </div>

        <b-card class="list-activity-card">
          <b-row class="mt-3 mx-1">
            <div class="col-lg-4 col-md-6 my-auto">
              <div class="fund-name">
                <b-row>
                  <div class="col-2 col-lg-2 text-left mb-2">
                    <div v-if="portfolio.product.image == null">
                      <img
                        :src="
                          'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          portfolio.product.fund_name
                        "
                      />
                    </div>
                    <div v-else>
                      <img
                        :src="`${imageCore}/images/products/${portfolio.product.image}`"
                        alt="Fund Image"
                      />
                    </div>
                  </div>
                  <div class="col-10 px-4 text-left">
                    <b-button class="btn-modal-portfolio">
                      <h4>
                        {{ portfolio.product.fund_name }}
                      </h4>
                    </b-button>
                    <p class="mt-n2">
                      {{ portfolio.product.fund_type }}
                    </p>
                  </div>
                </b-row>
              </div>
            </div>
            <div class="col-lg-8 col-md-6 my-auto text-center text-lg-right">
              <div class="switching-value-before">
                <p>{{ formatNab(unit_from_porto_detail_input)  }} unit</p>
                <h2>IDR{{ formatPrice(amount_from_porto_detail) }}</h2>
              </div>
            </div>
          </b-row>
        </b-card>

        <div class="switching-to">
          <b-row class="mt-4 mb-2">
            <b-col class="my-auto">
              <p class="title">Alihkan ke produk lain</p>
              <p class="subtitle">Pilih Tujuan Pengalihan</p>
            </b-col>
            <!-- <b-col class="my-auto text-right">
                    <b-button href="#" class="btn-outline-primary"><b-icon icon="filter"></b-icon> Filter</b-button>
                </b-col> -->
          </b-row>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="my-4">
              <b-skeleton-table
                :rows="5"
                :columns="10"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <b-row class="justify-content-md-start">
            <template>
              <div class="table-responsive table-product">
                <b-table
                  responsive
                  id="reksadana"
                  striped
                  hover
                  outlined
                  :items="switchingList"
                  :filter="filter"
                  :fields="fields"
                  :per-page="perPage"
                  :current-page="currentPage"
                  sticky-header="80vh"
                >
                  <template v-slot:head(navOneday)="data">
                    <div style="width: 100px">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>

                  <template v-slot:head(navOneMonth)="data">
                    <div style="width: 100px">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>

                  <template v-slot:head(navOneYear)="data">
                    <div style="width: 100px">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>

                  <template v-slot:head(navYtd)="data">
                    <div style="width: 100px">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>

                  <template v-slot:head(navThreeYear)="data">
                    <div style="width: 100px">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>

                  <template v-slot:head(navFiveYear)="data">
                    <div style="width: 100px">
                      <span>{{ data.label }}</span>
                    </div>
                  </template>

                  <template v-slot:cell(fund_name)="data">
                    <div class="row">
                      <td class="noborder" style="width: 300px">
                        <router-link
                          :to="
                            '/switching-product-detail/' +
                            fund_code +
                            '/' +
                            data.item.fund_code +
                            '/' +
                            data.item.id
                          "
                          >{{ data.item.fund_name }}</router-link
                        >
                        <p>{{ data.item.im_name }}</p>
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(fund_type)="data">
                    <div class="row">
                      <td class="noborder" style="width: 160px">
                        {{ data.item.fund_type }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(nav_per_unit)="data">
                    <div class="row">
                      <td class="noborder" style="width: 120px">
                        {{ formatNab(data.item.nav_per_unit)  }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navAum)="data">
                    <div class="row">
                      <td style="width: 200px">
                        {{ formatPrice(data.item.navAum) }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navOneday)="data">
                    <div class="row">
                      <td
                        style="width: 100px"
                        class="text-left noborder"
                        :class="{
                          'text-success': data.item.navOneday >= 0,
                          'text-danger': data.item.navOneday < 0,
                        }"
                      >
                        <template v-if="data.item.navOneday >= 0">
                          <b-icon icon="caret-up-fill"> </b-icon>
                        </template>
                        <template v-else>
                          <b-icon icon="caret-down-fill"> </b-icon>
                        </template>
                        {{ data.item.navOneday | formatPercent }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navOneMonth)="data">
                    <div class="row">
                      <td
                        style="width: 100px"
                        class="text-left noborder"
                        :class="{
                          'text-success': data.item.navOneMonth >= 0,
                          'text-danger': data.item.navOneMonth < 0,
                        }"
                      >
                        <template v-if="data.item.navOneMonth >= 0">
                          <b-icon icon="caret-up-fill"> </b-icon>
                        </template>
                        <template v-else>
                          <b-icon icon="caret-down-fill"> </b-icon>
                        </template>
                        {{ data.item.navOneMonth | formatPercent }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navOneYear)="data">
                    <div class="row">
                      <td
                        style="width: 100px"
                        class="text-left noborder"
                        :class="{
                          'text-success': data.item.navOneYear >= 0,
                          'text-danger': data.item.navOneYear < 0,
                        }"
                      >
                        <template v-if="data.item.navOneYear >= 0">
                          <b-icon icon="caret-up-fill"> </b-icon>
                        </template>
                        <template v-else>
                          <b-icon icon="caret-down-fill"> </b-icon>
                        </template>
                        {{ data.item.navOneYear | formatPercent }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navYtd)="data">
                    <div class="row">
                      <td
                        style="width: 100px"
                        class="text-left noborder"
                        :class="{
                          'text-success': data.item.navYtd >= 0,
                          'text-danger': data.item.navYtd < 0,
                        }"
                      >
                        <template v-if="data.item.navYtd >= 0">
                          <b-icon icon="caret-up-fill"> </b-icon>
                        </template>
                        <template v-else>
                          <b-icon icon="caret-down-fill"> </b-icon>
                        </template>
                        {{ data.item.navYtd | formatPercent }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navThreeYear)="data">
                    <div class="row">
                      <td
                        style="width: 100px"
                        class="text-left noborder"
                        :class="{
                          'text-success': data.item.navThreeYear >= 0,
                          'text-danger': data.item.navThreeYear < 0,
                        }"
                      >
                        <template v-if="data.item.navThreeYear >= 0">
                          <b-icon icon="caret-up-fill"> </b-icon>
                        </template>
                        <template v-else>
                          <b-icon icon="caret-down-fill"> </b-icon>
                        </template>
                        {{ data.item.navThreeYear | formatPercent }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(navFiveYear)="data">
                    <div class="row">
                      <td
                        style="width: 100px"
                        class="text-left noborder"
                        :class="{
                          'text-success': data.item.navFiveYear >= 0,
                          'text-danger': data.item.navFiveYear < 0,
                        }"
                      >
                        <template v-if="data.item.navFiveYear >= 0">
                          <b-icon icon="caret-up-fill"> </b-icon>
                        </template>
                        <template v-else>
                          <b-icon icon="caret-down-fill"> </b-icon>
                        </template>
                        {{ data.item.navFiveYear | formatPercent }}
                      </td>
                    </div>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div class="row">
                      <td>
                        <router-link
                          :to="
                            '/switching-product-detail/' +
                            fund_code +
                            '/' +
                            data.item.fund_code +
                            '/' +
                            data.item.id
                          "
                          class="btn-primary noborder px-4 py-2 inline-block"
                          >Alihkan
                        </router-link>
                      </td>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="table-product"
                  class="customPagination"
                ></b-pagination>
              </div>
            </template>
          </b-row>
        </b-skeleton-wrapper>
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import numeral from "numeral";

import instance from "@/utils/api";

export default {
  name: "SwitchingListProduct",
  components: {
    Header,
    HeaderBottom,
    Footer,
  },
  mounted() {
    document.title = "Daftar Produk Pengalihan | CGS iTrade Fund";
  },
  data: () => ({
    portfolio: {
      product: {
        fund_name: "",
        nav: {
          nav_per_unit: "",
        },
      },
    },
    fund_code: "",
    products: [],
    filterFundType: null,
    loading: false,
    loadingTime: 0,
    maxLoadingTime: 3,
    fields: [
      {
        key: "fund_name",
        label: "Reksa Dana",
        sortable: true,
      },
      {
        key: "actions",
        label: "",
        sortable: false,
      },
      {
        key: "fund_type",
        label: "Jenis",
        sortable: true,
      },
      {
        key: "nav_per_unit",
        label: "NAV/UP",
        sortable: true,
      },
      {
        key: "navAum",
        label: "AUM",
        sortable: true,
      },
      {
        key: "navOneday",
        label: "1 hr (%)",
        sortable: true,
      },
      {
        key: "navOneMonth",
        label: "1 bln (%)",
        sortable: true,
      },
      {
        key: "navOneYear",
        label: "1 thn (%)",
        sortable: true,
      },
      {
        key: "navYtd",
        label: "YTD (%)",
        sortable: true,
      },
      {
        key: "navThreeYear",
        label: "3 thn (%)",
        sortable: true,
      },
      {
        key: "navFiveYear",
        label: "5 thn (%)",
        sortable: true,
      },
    ],
    filter: "",
    perPage: 10,
    currentPage: 1,
    unit_from_porto_detail_input: 0,
    amount_from_porto_detail: 0,
    // fund_types: [],
    // product: [],
    porto_detail: {},
    imageCore: process.env.VUE_APP_IMAGE_URL,
  }),
  created() {
    this.fund_code = this.$route.params.fund_code;
    this.im_id = this.$route.params.im_id;
    this.fetchFundsList();
    this.fetchPortfolio();
    setInterval(this.getNow, 1000);
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      user: "auth/user",
      app_url: "app_url",
    }),
    rows() {
      return this.products.length;
    },
    switchingList: function () {
      var fundCode = this.$route.params.fund_code;
      return this.products.filter(function (u) {
        return u.fund_code != fundCode;
      });
    },
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      this.currentDate = date;
    },

    async fetchFundsList() {
      this.loading = true;
      let response = await axios({
        method: "GET",
        url: `3041?api=1&type=1&im_id=${this.im_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      this.products = response.data;
      this.loadingTime = 0;
    },

    async fetchPortfolio() {
      if (this.user.institutional == null) {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Individual&id=${this.user.individual.id}&fund_code=${this.fund_code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        }).catch(() => {
          // Kalo portofolio product tidak ditemukan
          this.$router.push("/NOT-FOUND");
        });

        this.portfolio = response.data.data.userPortfolio[0];
        this.porto_detail = response.data.data.portoDetail;
        this.amount_from_porto_detail =
          this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
        this.unit_from_porto_detail_input = numeral(
          this.porto_detail.balance_unit
        ).format("0,0.0000");
      } else {
        let response = await instance({
          method: "GET",
          url: `${this.app_url}user-portfolio-product?type=Institutional&id=${this.user.institutional.id}&fund_code=${this.fund_code}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `${this.access_token}`,
          },
        });
        this.portfolio = response.data.data.userPortfolio;
        this.porto_detail = response.data.data.portoDetail;
        this.amount_from_porto_detail =
          this.porto_detail.balance_unit * this.porto_detail.nav_per_unit;
        this.unit_from_porto_detail_input = numeral(
          this.porto_detail.balance_unit
        ).format("0,0.0000");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switching-order {
  background: #f3f4f6;
}
.switching-container {
  padding-top: 120px;
  padding-bottom: 40px;
}

.portfolio-product {
  background: #f3f4f6;
}

.portfolio-product-container {
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.switching-title {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;

    color: #111827;
  }
}

.noborder {
  border: none;
}

.switching-from {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    margin-bottom: 8px;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.switching-to {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
    margin-bottom: 8px;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.list-activity-card {
  background: #f7f7f8;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #5c0dab;
    text-align: left;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

.switching-value-before {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
    margin-bottom: 4px;
  }
  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.back-nav {
  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

hr {
  margin: 0 !important;
}

.table-product {
  padding-top: 16px;
  margin-left: 8px;
}

thead {
  background: #f9fafb;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #f9fafb;
}

table th {
  font-family: $inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;

  color: #4b5563;
}

table a {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #5c0dab;
  text-decoration: none;
}

table td {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #111827;
}

table td p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;

  color: #6b7280;
}

table {
  border: none !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}
table tr th,
table tr:first-child th:first-child {
  border-top-left-radius: 0.25rem !important;
}

table tr:first-child th:last-child {
  border-top-right-radius: 0.25rem !important;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 0.25rem !important;
}

.borderless th,
.borderless tr {
  border: none !important;
}

.borderless td {
  border-top: solid 1px #e5e7eb !important;
  border-bottom: solid 1px #e5e7eb !important;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.product-image {
  border-radius: 6px;
  width: 40px;
}

.buy-product a {
  text-decoration: none;
}

.buy-product a:focus {
  color: white !important;
}

.buy-product a:active {
  color: white !important;
}

.btn-modal-portfolio {
  font-family: $inter;
  background: transparent;
  padding: 0;
  border: none;
}

.btn-modal-portfolio:hover {
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
  background: transparent !important;
}

.btn-modal-portfolio:focus {
  box-shadow: none !important;
}

.btn-modal-portfolio:active {
  background: transparent !important;
  outline: none !important;
}
</style>